import React, { useState, useEffect } from "react";
import "../css/Pricing.css";
import phone1 from "../images/Group 1686556119.png";
import phone2 from "../images/Mask group.png";
import google from "../images/Container-google.png";
import apple from "../images/Container-apple.png";
import qr from "../images/qr.png";
import rates from "../images/Great Rates.png";
import security from "../images/Simplicity and Security.png";
import quality from "../images/Excellent quality.png";
import vector1 from "../images/Vector.png";
import vector2 from "../images/Vector (1).png";
import vector3 from "../images/Vector (2).png";
import vector4 from "../images/Vector (3).png";
import vector5 from "../images/Vector (4).png";
import vector6 from "../images/Vector (5).png";
import { Link, useSearchParams } from "react-router-dom";


const faqData = [
  {
    question: "How to top up a mobile phone with Dehay Revolution?",
    answer: "Lorem Ipsum Text...",
  },
  {
    question: "How to top up a mobile phone online?",
    answer: "Lorem Ipsum Text...",
  },
  {
    question: "How to top up someone else's phone?",
    answer: "Lorem Ipsum Text...",
  },
  {
    question: "Why is Dehay Revolution the best site for mobile recharge?",
    answer: "Lorem Ipsum Text...",
  },
];

interface Country {
  countryName: string;
  code: string;
  phoneCode: string;
}

const Pricing: React.FC = () => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  console.log('search params');
  console.log(searchParams.get('country'));
  
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [packageList,setPackageList] = useState<any[]>([]);
  const [paygList,setPaygList] = useState<any[]>([]);
  
  const [data, setData] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  async function apiPackagesCall(country:any) {
    try {
      const url = `https://dehaymobile.app:4443/api/getpackages?username=webapi&password=webapi&packagename=${country}&offset=0&size=50`;
      const response = await fetch(url);
      console.log('API Response status:', response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response data:', JSON.stringify(data, null, 2));
      if (!data.data || !Array.isArray(data.data)) {
          console.error('Unexpected API response structure:', data);
          return;
      }
      setPackageList(data.data);
  } catch (error) {
      console.error('Error fetching transactions:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  }
  
  async function apiPaygCall(country:any) {
    // PAYG rates
  try {
    const url = `https://dehaymobile.app:4443/api/getdefaultsheet?username=webapi&password=webapi&countryname=${country}&countrycode=*&rateplan=default&sheet=0&offset=1&size=10`;
    const response = await fetch(url);
    console.log('API Response status:', response.status);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('API Response data:', JSON.stringify(data, null, 2));
    if (!data.data || !Array.isArray(data.data)) {
        console.error('Unexpected API response structure:', data);
        return;
    }
    setPaygList(data.data);
} catch (error) {
    console.error('Error fetching payg rates:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
  }
  }
  useEffect(() => {
    // Fetch the JSON file from the public directory
    fetch('/data.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data: Country[]) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
      
      if(searchParams.get('country') != null) {
        var cc = searchParams.get('country')||'India';
        setSelectedCountry(cc);
        apiPackagesCall(cc);
        apiPaygCall(cc);
      }
      
  }, []);

  const handleCountryClick = async (country: string) => {
    setSelectedCountry(country);
    setIsExpanded(false);    
    apiPackagesCall(country);
      apiPaygCall(country);
      
  };

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  
  return (
    <div className="container">
      <h1 className="responsive-heading">International calling</h1>
      <p className="responsive-text">
        Stay connected with your loved ones and get a $2 bonus when you create a
        new account with Dehay mobile.
      </p>
      <div className="country-selector">
        <button
          className="country-select-btn"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {selectedCountry || "Select a Country ?"}
        </button>
        {isExpanded && (
          <div className="country-dropdown">
            {data.map(item => (
              <div
                key={item.countryName}
                className="country-option"
                onClick={() => handleCountryClick(item.countryName)}
              >
                <img src={`/flags/1x1/${item.code.toLowerCase()}.svg`} alt={`${item.countryName} flag`} height="20"
  width="30"/>
                <span>{item.countryName}</span>
                <span className="country-code">({item.phoneCode})</span>
              </div>
            ))}
          </div>
        )}
      </div>
      

      <div className="pay-as-you-go-section">
        <h2 className="responsive-heading">Pay as you go</h2>
        <p className="responsive-text">If you choose "Pay as you go", you can expect to use these rates for different services in your country. Total is applicable to single call minutes.</p>
        <div className="payg-list">
          {paygList.map((p: any, index: number) => (
            <div key={index} className="paygplan">
              <span className="paygitem">{p.countryname}</span>
              <span className="paygitem-price">$ {p.cost} / min</span>                    
            </div>
          ))}
        </div>
        <Link to="/login" className="topup-login-button">Buy Credit</Link>
      </div>
      <div className="calling-plans-section">
        <h2 className="responsive-heading">Calling Plans</h2>
        <p className="responsive-text">All calling plans above are only available in Dehay Mobile app. You can download our app through the Appstore or Google Play.</p>
        <div className="plan-list">
          {packageList.map((p: any, index: number) => (
            <div key={index} className="plan">
              <h5>{p.packagename}</h5>
              <span className="planitem">$ {p.cost}</span>
              <span className="planitem">Valid for {p.validity} days</span>
            </div>
          ))}
        </div>
      </div>


      <div className="features-and-phone-section">
        <div className="app-features">
          <h3 className="responsive-heading">
            Dehay Mobile Calling
            <br /> App Features
          </h3>
          <div className="features">
            <div className="feature">
              <h4>Call</h4>
              <p>Any country with great rates and crystal clear connection</p>
            </div>
            <div className="feature">
              <h4>Enjoy</h4>
              <p>Promotion from your favorite and crystal clear connection</p>
            </div>
            <div className="feature">
              <h4>Send</h4>
              <p>Mobile top-ups to your friends and family in a few clicks</p>
            </div>
            <div className="feature">
              <h4>Message</h4>
              <p>And share your photos and videos for free</p>
            </div>
          </div>
          <div className="app-download">
            <img src={apple} alt="Download on the App Store" className="store-badge" />
            <img src={google} alt="Get it on Google Play" className="store-badge" />
            <img src={qr} alt="Scan QR to download app" className="qr-code" />
          </div>
        </div>
        <div className="phone-image-section">
          <img src={phone2} alt="Phone" className="phone-image-right" />
        </div>
      </div>


      <div className="benefits-section">
        <h2>Why make international calls with Dehay Mobile?</h2>
        <div className="benefits-cards">
          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={rates} alt="Great Rates" />
            </div>
            <h3>Great Rates</h3>
            <p>
              Who said staying connected had to cost a fortune? Whether you're
              calling internationally to Haiti, Kenya, Nigeria, Ghana or
              Guatemala with DEHAY Mobile, you can rest assured that you are
              getting the best calling rates around. This is a better way to
              make cheap international calls, no matter your location. Save up
              to 90% compared to a standard mobile carrier.
            </p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={quality} alt="Excellent Quality" />
            </div>
            <h3>Excellent Quality</h3>
            <p>
              Experience the best call quality with DEHAY Mobile. Whether you're
              calling over Wi-Fi or using mobile data, our app ensures that you
              get the best possible connection, with crystal clear audio and
              minimal interruptions.
            </p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon">
              <img src={security} alt="Simplicity and Security" />
            </div>
            <h3>Simplicity and Security</h3>
            <p>
              Our app is easy to use, with a simple interface that makes it easy
              to make calls, send messages, and top-up mobile accounts. Plus,
              with our strong security measures, you can be sure that your calls
              and data are safe.
            </p>
          </div>
        </div>
      </div>
      <div className="testimonials-section">
        <h2>What Our Customers Say About Dehay Mobile App</h2>
        <div className="testimonials-cards">
          <div className="testimonial-card">
            <img src={vector1} alt="Customer 1" className="testimonial-img" />
            <h3>John Jamees</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
          <div className="testimonial-card">
            <img src={vector2} alt="Customer 2" className="testimonial-img" />
            <h3>Theresa Webb</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
          <div className="testimonial-card">
            <img src={vector3} alt="Customer 3" className="testimonial-img" />
            <h3>Eleanor Pena</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
          <div className="testimonial-card">
            <img src={vector4} alt="Customer 4" className="testimonial-img" />
            <h3>Brooklyn Simmons</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
          <div className="testimonial-card">
            <img src={vector5} alt="Customer 5" className="testimonial-img" />
            <h3>Leslie Alexander</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
          <div className="testimonial-card">
            <img src={vector6} alt="Customer 6" className="testimonial-img" />
            <h3>Robert Fox</h3>
            <div className="stars">★★★★★</div>
            <p>
              The best app to call international. Thank you for your service.
              Wonderful app to call Honduras, easy, clear.
            </p>
          </div>
        </div>
      </div>
      <div className="faq-section">
        <h2>Got questions?</h2>
        <p>
          At Dehay Revolution we do our best to help you solve all the possible
          issues. Please check out our FAQ page or contact our customer service
          team if you have any questions.
        </p>
        <div className="faq-buttons">
          <Link to="/contact-us" className="contact-us-btn">
            Contact us
          </Link>
          <Link to="/support" className="faq-btn">
            Faq
          </Link>
        </div>
        <div className="faq-accordion">
          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div
                className={`faq-question ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleToggle(index)}
              >
                {item.question}
                <span className="faq-icon">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">
                  <p>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
